export const getDomPath = (element: Element) => {
  const stack = [];

  while (element != null) {
    stack.unshift(
      `${element.nodeName.toLowerCase()}${element.id ? `#${element.id}` : ''}${
        element.classList.length > 0 ? `.${element.className.split(' ').join('.')}` : ''
      }`,
    );

    element = element.parentElement;
  }

  return stack;
};
